'use client';

import React from 'react';

import { ErrorHandler } from '@web/errorHandler';

type GlobalErrorProps = {
  error: Error;
  reset: () => void;
};

const GlobalError: React.FC<GlobalErrorProps> = ({ error, reset }) => {
  return <ErrorHandler error={error} reset={reset} />;
};

export default GlobalError;
